header.video {
  position: relative;
  background-color: #5DA83F;
  height: 50vh;
  display: block;
  min-height: 25rem;
  width: 100%;
  overflow: hidden;
}

header.video video {
  position: absolute;
  top: 50%;
  left: 50%;
  min-width: 100%;
  min-height: 100%;
  max-width: 1140px;
  width: auto;
  height: auto;
  z-index: 0;
  -ms-transform: translateX(-50%) translateY(-50%);
  -moz-transform: translateX(-50%) translateY(-50%);
  -webkit-transform: translateX(-50%) translateY(-50%);
  transform: translateX(-50%) translateY(-50%);
}

header.video .container {
  position: relative;
  z-index: 2;
}
@media (pointer: coarse) and (hover: none) {
  header.video {
    background: #5DA83F no-repeat center center scroll;
  }

  header.video video {
    display: none;
  }
}

.App {
  text-align: center;
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}



/* //////////// M Y -- S T Y L E S */
.news-hero {

  position: relative;
  background-image: url('https://images.unsplash.com/photo-1457530378978-8bac673b8062?crop=entropy&cs=tinysrgb&fit=max&fm=jpg&ixid=MXwxfDB8MXxhbGx8fHx8fHx8fA&ixlib=rb-1.2.1&q=80&w=1080&utm_source=unsplash_source&utm_medium=referral&utm_campaign=api-credit');
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center center;
  overflow: hidden;
}

.main-hero{
  background-color: #5da83f;
  position: relative;
  /* background-image: url('https://images.unsplash.com/photo-1587169897559-81b1e83de0c4?crop=entropy&cs=tinysrgb&fit=max&fm=jpg&ixid=MnwxfDB8MXxyYW5kb218MHx8fHx8fHx8MTYzMDU4OTY1OA&ixlib=rb-1.2.1&q=80&utm_campaign=api-credit&utm_medium=referral&utm_source=unsplash_source&w=1080'); */
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center center;
  overflow: hidden;
}


.divider::after {
  content: '';
  display: block;
  width: 15%;
  min-width: 100px;
  max-width: 200px;
  height: 4px;
  border-radius: 1rem;
  background: #67bc45;
  margin: 0 auto;
  margin-top: 1rem;
}

.divider-left {
  position: relative;
}

.divider-left::after {
  content: '';
  display: block;
  position: absolute;
  left: 1rem;
  width: 20%;
  min-width: 10px;
  max-width: 200px;
  height: 3px;
  background: #67bc45;
  margin: 0 auto;
}

.text-smartgrow {
  color: #67bc45;
}
.btn-smartgrow {
  background: #67bc45;
  color: #fff;
}

.btn-smartgrow:hover {
  background: #52a532;
  color: #fff;
}

.btn-outline-smartgrow {
  border: 1px solid #67bc45;
  color: #67bc45;
}

.btn-outline-smartgrow:hover {
  border: 1px solid #67bc45;
  background: #67bc45;
  color: #fff;
}

.bg-smartgrow-light {
  background: rgba(103, 188, 69, 0.5);
}

.bg-smartgrow-medium {
  background: rgba(103, 188, 69, 0.7);
}

.bg-smartgrow {
  background: rgba(103, 188, 69, 0.9);
}

.breadcrumb > .breadcrumb-item > a {
  color: #67bc45;
}

.breadcrumb > .breadcrumb-item > a:hover {
  color: #5da83f;
}

.gallery-item, .img-responsive {
  margin: 5px;
  cursor: pointer;
}



#partnershipTab .nav-link::before {
  display: none;
}

#partnershipTabContent > div {
  border: 1px solid #d3d3d3;
  border-radius: 0.3rem;
}

#partnershipTab.nav-tabs {
  border-bottom: none;
}

#partnershipTab button{
  background-color:rgba(189, 201, 184, 0.5);
  color: gray;
  border-radius: 0.3rem;
  font-weight: normal;
}

#partnershipTab button.active{
  background-color: rgba(103, 188, 69, 1);
  color: #fff;
  font-weight: bold;
  border: 7px solid rgba(103, 188, 69, 1);
}




